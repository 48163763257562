var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"cus-modal education-change-dialog",attrs:{"width":"620px","footer":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"title"},[_c('p',{staticClass:"text-center"},[_vm._v("发送私信")])]),_c('div',{staticClass:"info"},[_c('a-form',{staticClass:"cus-form"},[_c('a-form-item',{attrs:{"label":"收件人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填',
                  },
                ],
              },
            ]),expression:"[\r\n              'field1',\r\n              {\r\n                rules: [\r\n                  {\r\n                    required: true,\r\n                    message: '必填',\r\n                  },\r\n                ],\r\n              },\r\n            ]"}],attrs:{"placeholder":"请选择收件人"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 张三 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 李四 ")])],1)],1),_c('a-form-item',{attrs:{"label":"正文"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填',
                  },
                ],
              },
            ]),expression:"[\r\n              'field1',\r\n              {\r\n                rules: [\r\n                  {\r\n                    required: true,\r\n                    message: '必填',\r\n                  },\r\n                ],\r\n              },\r\n            ]"}],attrs:{"placeholder":"填写正文","auto-size":{ minRows: 5, maxRows: 10 }}})],1),_c('div',{staticClass:"text-center"},[_c('a-form-item',[_c('a-button',{staticClass:"cus-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("立即发送")])],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }