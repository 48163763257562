<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 11:57:37
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 14:10:08
 * @FilePath: \crm-education\src\views\teacher\notice\index.vue
-->
<template>
  <div class="teacher-notice">
    <a-card class="cus-card no-border" :bordered="false">
      <a-tabs v-model="activeKey" class="cus-tabs">
        <a-tab-pane key="1">
          <template slot="tab">
            <p class="fs-18">通知公告</p>
          </template>
          <div class="py-5 d-flex">
            <a-form
              class="cus-form search-form"
              layout="inline"
              :form="form"
              @submit="onQueryList"
            >
              <a-row type="flex" align="middle">
                <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                  <a-form-item label="通知标题">
                    <a-input v-decorator="['userName']"> </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                  <a-form-item label="通知类型" has-feedback>
                    <a-select
                      class="cus-select"
                      v-decorator="['select']"
                      placeholder="选择类别"
                    >
                      <a-select-option value="1">
                        类别1123
                      </a-select-option>
                      <a-select-option value="2">
                        类别212321
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 4 }" :xs="{ span: 24 }">
                  <a-form-item>
                    <a-button
                      type="primary"
                      html-type="submit"
                      class="cus-button"
                    >
                      查询
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
            <div class="d-flex align-center link mr-20" @click="openNotice">
              <span class="send-msg cus-icon"></span>
              <p class="ml-10 fs-14">新建通知</p>
            </div>
          </div>
          <a-list
            item-layout="horizontal"
            :data-source="dataList"
            :pagination="paginationObject"
          >
            <div
              class="list-header d-flex justify-between align-center px-15"
              slot="header"
            >
              <p class="fs-14">内容</p>
              <div class="list-item-right d-flex align-center">
                <p class="fs-14 mr-30">操作</p>
                <p class="fs-14">时间</p>
              </div>
            </div>
            <a-list-item slot="renderItem" slot-scope="scope, index">
              <div
                class="list-item d-flex justify-between align-center px-15 link hover-color-blue"
              >
                <p class="fs-16 unread">第三学期的教材你收到了吗？</p>
                <div class="list-item-right d-flex align-center">
                  <p
                    class="fs-14 mr-30"
                    :class="index % 2 === 0 ? 'color-blue' : 'color-gray'"
                  >
                    撤回
                  </p>
                  <p class="fs-12 color-gray">2019-11-01</p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template slot="tab">
            <p class="fs-18">我的消息</p>
          </template>
          <div class="message-box d-flex justify-end align-center mb-10">
            <div class="d-flex align-center link mr-20" @click="openMsg">
              <span class="send-msg cus-icon"></span>
              <p class="ml-10 fs-14">发送私信</p>
            </div>
            <div class="d-flex align-center link" v-if="true">
              <span class="out-box cus-icon"></span>
              <p class="ml-10 fs-14">已发送</p>
            </div>
            <div class="d-flex align-center link" v-else>
              <span class="in-box cus-icon"></span>
              <p class="ml-10 fs-14">收信箱</p>
            </div>
          </div>
          <a-list
            item-layout="horizontal"
            :data-source="dataList"
            :pagination="paginationObject"
          >
            <div
              class="list-header d-flex justify-between align-center px-15"
              slot="header"
            >
              <p class="fs-14">内容</p>
              <div class="list-item-right d-flex align-center">
                <p class="fs-14">操作</p>
                <p class="fs-14">时间</p>
              </div>
            </div>
            <a-list-item slot="renderItem" slot-scope="scope, index">
              <div
                class="list-item d-flex justify-between align-center px-15 link hover-color-blue"
              >
                <p class="fs-16 unread">第三学期的教材你收到了吗？</p>
                <div class="list-item-right d-flex align-center">
                  <p
                    class="fs-14 mr-30"
                    :class="index % 2 === 0 ? 'color-blue' : 'color-gray'"
                  >
                    撤回
                  </p>
                  <p class="fs-12 color-gray">2019-11-01</p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <send-msg-dialog ref="sendMsgDialog"></send-msg-dialog>
    <send-notice-dialog ref="sendNoticeDialog"></send-notice-dialog>
  </div>
</template>

<script>
import SendMsgDialog from './c/SendMsgDialog'
import SendNoticeDialog from './c/SendNoticeDialog'

export default {
  name: 'teacher-notice',
  components: {
    SendMsgDialog,
    SendNoticeDialog
  },
  data () {
    return {
      activeKey: '1',
      selectKey: '1',
      form: null,
      dataList: [{}, {}],
      paginationObject: {
        current: 1,
        total: 20,
        pageSize: 10,
        onChange: this.onPageChange
      }
    }
  },
  methods: {
    openMsg () {
      this.$refs.sendMsgDialog.open()
    },
    openNotice () {
      this.$refs.sendNoticeDialog.open()
    },
    onQueryList () {},
    onPageChange (page, pageSize) {
      this.paginationObject.current = page
    }
  }
}
</script>
<style lang="scss" scoped>
.teacher-notice {
  ::v-deep .ant-list-header {
    background-color: #eff2fb;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .list-item-right {
    width: 200px;
    justify-content: space-between;
  }
  .list-item {
    width: 100%;
    .unread {
      position: relative;
      &::after {
        position: absolute;
        right: -4px;
        top: 0px;
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: #fc3f3f;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .search-form {
    flex: 1;
  }
  .cus-icon {
      display: block;
      width: 18px;
      height: 18px;
      background-size: 14px 14px;
      &.send-msg {
        background: url("../../../assets/img/notice-link-plus.png") no-repeat
          center center;
      }
      &.out-box {
        background: url("../../../assets/img/my-notice-outbox.png") no-repeat
          center center;
      }
      &.in-box {
        background: url("../../../assets/img/my-notice-inbox.png") no-repeat
          center center;
      }
    }
}
</style>
