<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 14:07:34
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 14:10:22
 * @FilePath: \crm-education\src\views\teacher\notice\c\SendNoticeDialog.vue
-->
<template>
<a-modal
    width="620px"
    class="cus-modal education-change-dialog"
    v-model="visible"
    :footer="false"
  >
    <template slot="title">
      <p class="text-center">新建通知</p>
    </template>
    <div class="info">
      <a-form class="cus-form">
        <a-form-item label="班级">
          <a-select
            v-decorator="[
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填',
                  },
                ],
              },
            ]"
            placeholder="请选择收件人"
          >
            <a-select-option value="1">
              全部
            </a-select-option>
            <a-select-option value="2">
              一般
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="标题">
          <a-input
             v-decorator="[
              'field3',
              {
                rules: [
                  {
                    required: true,
                    message: '必填',
                  },
                ],
              },
            ]"
            placeholder="填写标题"
          ></a-input>
        </a-form-item>
        <a-form-item label="正文">
          <a-textarea
            v-decorator="[
              'field1',
              {
                rules: [
                  {
                    required: true,
                    message: '必填',
                  },
                ],
              },
            ]"
            placeholder="填写正文"
            :auto-size="{ minRows: 5, maxRows: 10 }"
          >
          </a-textarea>
        </a-form-item>
        <div class="text-center">
          <a-form-item>
            <a-button type="primary" html-type="submit" class="cus-button"
              >立即发送</a-button
            >
          </a-form-item>
        </div>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'sent_msg_dialog',
  data () {
    return {
      visible: false,
      form: null
    }
  },
  methods: {
    open () {
      this.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.cus-form {
  ::v-deep .ant-form-item {
    .ant-form-item-label {
      min-width: 130px;
    }
  }
}